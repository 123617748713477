<template lang="pug">
  //- search
  article.aro-index
    landing
    slice-body(:slices="doc.body", v-if="doc")
</template>

<script>
import { mapState } from 'vuex'
import SliceBody from '@/components/SliceBody'
export default {
  name: 'AroIndex',
  computed: {
    ...mapState({ doc: state => state.aro })
  },
  metaInfo () {
    return this.doc && {
      meta: this.$store.getters.meta({})
    }
  },
  components: { SliceBody }
}
</script>
