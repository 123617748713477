<template lang="pug">
  .aro.min-h-screen.flex.flex-col(:class="{'aro--loading': loading}")
    aro-header
    //- main
    main-el.flex-1
      transition(:name="transition", @after-leave="afterRouteLeave")
        router-view(:key="$route.path", @loading="$event => { loading = $event }")
    //- footer
    aro-footer
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import store from '@/store'
// let lastRt
export default {
  name: 'Aro',
  data () {
    return {
      loading: false,
      siteLanding: this.$route.name === 'aro-index',
      transition: 'whitewash-right',
      menu: null
    }
  },
  computed: {
    ...mapState(['aro'])
  },
  methods: {
    ...mapMutations(['setLanding']),

    afterRouteLeave () {
      this.siteLanding = this.$route.name === 'aro-index'
      setTimeout(() => this.$root.$emit('afterRouteLeave'), 50)
      setTimeout(() => this.$root.$emit('afterRouteLeave2'), 100)
    },
    setTransitionOnRoute (to) {
      // no transition when menu is open and filtering
      const disableTransition = this.menu === 'menu' && to.fullPath !== to.path
      this.transition = disableTransition ? 'none' : 'whitewash-right'
    }
  },

  // lifecycle
  beforeRouteEnter (to, from, next) {
    store.dispatch('getAro')
    // TODO - remove all the setLanding logic (since always visible / Home exists)
    store.commit('setLanding', false)
    // lastRt = from
    next()
  },
  created () {
    this.siteLanding = this.$route.name === 'aro-index'
    this.$root.$on('menu', menu => { this.menu = menu })
  },
  watch: {
    '$route' (to, from) {
      this.setTransitionOnRoute(to)
    }
  }
}
</script>

<style>
.aro--loading .slice-hero-vimeo__heading,
.aro--loading .slice-collection-synopsis__heading{
  opacity:0;
}
</style>
